(function (window) {
  const isNotificationClosed = window.localStorage.getItem('yotitleClosed');

  if (isNotificationClosed) {
    return;
  }

  const handleClick = () => {
    window.localStorage.setItem('yotitleClosed', true);
    document.querySelector('.header').classList.remove('activeYotitle');
    window.removeEventListener('scroll', handleScroll);
  }

  const handleScroll = () => {
    if (document.documentElement.scrollTop === 0) {
      document.querySelector('.header').classList.add('activeYotitle');
    } else {
      document.querySelector('.header').classList.remove('activeYotitle');
    }
  }

  const handleMenuClick = () => {
    if (!document.querySelector('#mainMenu.mobile').classList.contains('active')) {
      document.querySelector('.yotitleNotification').style = '';
      window.addEventListener('scroll', handleScroll);
    } else {
      document.querySelector('.yotitleNotification').style.display = 'none';
      document.querySelector('.header').classList.remove('activeYotitle');
      window.removeEventListener('scroll', handleScroll);
    }
  }

  document.querySelector('.header').classList.add('activeYotitle');
  document.querySelector('.yotitleNotification .closeIcon').onclick = handleClick;
  window.addEventListener('scroll', handleScroll);
  setTimeout(() => {
    document.querySelector('#mainMenu .menuIcon').addEventListener('click', handleMenuClick);
  }, 1000);

})(window);